import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import GenericLink from '../GenericLink/GenericLink';
import { getInlineComponent } from '../../lib/ComponentFactory';
import cx from 'classnames';
import styles from './richText.module.scss';

/**
 * @param {object} [props] component Props
 * @param {object} [props.content] rich text object from contentful
 * @param {string} [props.className] classname used in the component
 * @param {string} [props.internalLinksInNewWindow] open internal links in new windows
 * @returns {JSX.Element} rendered react component from rich text object
 */
export default function RichText({ content, className, internalLinksInNewWindow }) {
  /* eslint-disable react/display-name */
  const options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, children) => {
        return <GenericLink externalLink={node.data.uri}>{children}</GenericLink>;
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return (
          <GenericLink
            internalLink={node.data.target}
            internalLinkTarget={internalLinksInNewWindow ? '_blank' : null}>
            {children}
          </GenericLink>
        );
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        // eslint-disable-next-line max-len
        return (
          <GenericLink externalLink={node.data.target.fields.file.url}>{children}</GenericLink>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        return getInlineComponent(node.data.target.sys.contentType.sys.id, node.data.target.fields);
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        return getInlineComponent(node.data.target.sys.contentType.sys.id, node.data.target.fields);
      },
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <div className={styles.tableContainer}>
            <table>
              <tbody>{children}</tbody>
            </table>
          </div>
        );
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        return <th>{children}</th>;
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
        return <tr>{children}</tr>;
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
        return <td>{children}</td>;
      },
    },
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return (
    <div className={cx(styles.container, className)}>
      {documentToReactComponents(content, options)}
    </div>
  );
}
